var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"grey lighten-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0 grey lighten-2"},[_c('v-spacer'),(_vm.editMode || _vm.createMode)?[_c('v-btn',{attrs:{"icon":"","small":"","loading":_vm.loading},on:{"click":_vm.onSaveClick}},[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-content-save")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.loading},on:{"click":_vm.onCancelClick}},[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-close-circle")])],1)]:[_c('v-btn',{attrs:{"disabled":_vm.disabled || _vm.deleting || _vm.readonly,"icon":"","small":""},on:{"click":_vm.onEditClick}},[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"disabled":_vm.disabled || _vm.readonly,"loading":_vm.deleting && _vm.deletedIndex === _vm.index,"icon":"","small":""},on:{"click":_vm.onRemoveClick}},[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v("mdi-delete")])],1)]],2),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.travel.detail_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('travel-detail-type-picker',{attrs:{"disabled":_vm.viewMode,"error-messages":errors},model:{value:(_vm.innerValue.travelDetailType),callback:function ($$v) {_vm.$set(_vm.innerValue, "travelDetailType", $$v)},expression:"innerValue.travelDetailType"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.travel.detail_subtype'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('travel-detail-subtype-picker',{attrs:{"disabled":_vm.viewMode,"detail-type":_vm.innerValue.travelDetailType,"error-messages":errors},model:{value:(_vm.innerValue.travelDetailSubType),callback:function ($$v) {_vm.$set(_vm.innerValue, "travelDetailSubType", $$v)},expression:"innerValue.travelDetailSubType"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.travel.begin_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"max":_vm.innerValue.endDate || _vm.maxDate,"min":_vm.minDate,"disabled":_vm.viewMode,"label":_vm.$t('hr.travel.begin_date'),"error-messages":errors},model:{value:(_vm.innerValue.beginDate),callback:function ($$v) {_vm.$set(_vm.innerValue, "beginDate", $$v)},expression:"innerValue.beginDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.travel.end_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"min":_vm.innerValue.beginDate || _vm.minDate,"max":_vm.maxDate,"disabled":_vm.viewMode,"label":_vm.$t('hr.travel.end_date'),"error-messages":errors},model:{value:(_vm.innerValue.endDate),callback:function ($$v) {_vm.$set(_vm.innerValue, "endDate", $$v)},expression:"innerValue.endDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"disabled":_vm.viewMode || _vm.disableSwitchButtons,"color":"teal","label":_vm.$t('hr.travel.is_rent_car')},model:{value:(_vm.innerValue.isRentCar),callback:function ($$v) {_vm.$set(_vm.innerValue, "isRentCar", $$v)},expression:"innerValue.isRentCar"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"disabled":_vm.viewMode || _vm.disableSwitchButtons,"color":"teal","label":_vm.$t('hr.travel.is_shuttle')},model:{value:(_vm.innerValue.isShuttle),callback:function ($$v) {_vm.$set(_vm.innerValue, "isShuttle", $$v)},expression:"innerValue.isShuttle"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.description'),"rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"disabled":_vm.viewMode,"label":_vm.$t('global.description'),"rows":"1","counter":"500","color":"tertiary","error-messages":errors},model:{value:(_vm.innerValue.comment),callback:function ($$v) {_vm.$set(_vm.innerValue, "comment", $$v)},expression:"innerValue.comment"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }